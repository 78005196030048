import React, { useState } from 'react';
import { IonContent, IonLoading, IonInput, IonButton, IonItem, IonToast, IonCard, IonCardContent, IonCardHeader, IonItemDivider, IonText, IonButtons, IonIcon,IonCol, IonGrid, IonRow, IonPage, useIonAlert, IonSelect, IonSelectOption } from '@ionic/react';
import logoImage from '../assets/img/icon.png';
import './Login.css';
import validator from 'validator';
import {Api} from '../services/api';
import { RouteComponentProps } from 'react-router';

import { create, ellipsisHorizontal, ellipsisVertical, helpCircle, linkOutline, logIn, reload, search, star, warningOutline } from 'ionicons/icons';

interface PortalAPIPageProps extends RouteComponentProps {
  api: Api
}

const PortalAPIPage: React.FC<PortalAPIPageProps> = ({api}) => {

  const [portal, setPortal] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [isNewPortal, setIsNewPortal] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);



  const onSubmit = async (e: any) => {
    setLoading(true);
    const result = api.submitPortalAPI(portal).then((res)=>{
        if(!res){
            setLoading(false);
            setShowToast(true);
        }
    }).catch((e)=>{
        setLoading(false);
        setShowToast(true);
    });
  };



  return (
    <IonPage>
        <IonContent className="bg-light  background-img">
            <div className="container">
            <IonCard>
                <IonCardHeader>
                    <div className="text-center">
                        <img src={logoImage} alt="Logo" className="img-fluid" />
                    </div>
                    
                </IonCardHeader>
                <IonItemDivider />
                <IonText color="medium" >
                    <p className="text-center">
                            <span>Please enter the Taro Solutions Portal</span>
                    </p>
                </IonText>
                <IonCardContent>
                <IonGrid>
                        {(api.portalList?.length > 0) && <IonRow class="ion-justify-content-end">
                            {(!isNewPortal) ? (<IonButton color="primary" size='small' fill="solid" onClick={(e) => {setIsNewPortal(true)}}>
                                    + Add New
                            </IonButton>):
                            (
                                <IonButton color="primary" size='small' fill="solid" onClick={(e) => {setIsNewPortal(false)}}>
                                    Select existing
                                </IonButton>
                            )
                        }   
                        </IonRow>}
                      </IonGrid>
                      <div className='d-flex align-items-baseline'>
                        <IonText color="medium" className='h5' >
                                <span>https://</span>
                        </IonText>
                        {
                            (api.portalList?.length > 0 && !isNewPortal) ? (
                                <IonSelect className='h4 bg-light text-dark rounded'  labelPlacement="fixed" placeholder="Select here" onIonChange={(e) => setPortal(e.detail.value)}>
                                    {
                                        api.portalList.map((item,index) => (<IonSelectOption key={index} value={item}>{item}</IonSelectOption>))
                                    }
                                </IonSelect>
                            ): (
                                <IonInput className='h4 bg-light text-dark rounded' placeholder="Type here" value={portal} fill="outline" onIonInput={(e) => setPortal(e.detail.value!)}></IonInput>
                            )
                        }
                        <IonText color="medium" className='h5' >
                                <span>.taro.solutions</span>
                        </IonText>
                      </div>
                      <IonGrid>
                          <IonRow class="ion-justify-content-center">
                              <IonButton color="success" fill="solid" onClick={(e) => {onSubmit(e)}}>
                                  <IonIcon slot="start" icon={linkOutline}></IonIcon>
                                      Go
                              </IonButton>
                          </IonRow>
                      </IonGrid>
                </IonCardContent>
            </IonCard>
            <IonToast
                icon={warningOutline}
                isOpen={showToast}
                color="danger"
                onDidDismiss={() => setShowToast(false)}
                message="The portal does not exist or is invalid. Please enter correct portal."
                duration={2000} // Set the duration in milliseconds
            />
            </div>
            <IonLoading
                isOpen={loading}
                onDidDismiss={() => setLoading(false)}
                message={'Please wait...'}
            />
        </IonContent>
    </IonPage>
    
  );
};

export default PortalAPIPage;
