import { useState, useEffect } from "react";
import { useHistory } from "react-router";

import { Storage } from '@ionic/storage';
import { Token, User, AuthenticationModule } from "../interfaces/authInterfaces.ds";

import useAuthenticationModule from "./authenticationModule";
import useRosterModule from "./RosterModule";
import { RosterModule } from "../interfaces/rosterinterfaces.ds";
import useTimesheetEntryModule from "./TimesheetEntryModule";
import { TimesheetEntryModule } from "../interfaces/timesheetentryinterfaces.ds";
// const portalAPI = 'https://markwell.taro.training/api';
const STORE = process.env.REACT_APP_STORE;

export interface Api{
    authentication: AuthenticationModule;
    rosters : RosterModule;
    timesheetEntries: TimesheetEntryModule;
    online: boolean;
    loading: boolean;
    logOut: () => Promise<false | undefined>;
    isportalAuth: boolean;
    clearPortalAPI: () => void;
    portalAPI : string;
    submitPortalAPI: (portal: any) => Promise<boolean | undefined>;
    portalList : string[],
    timeEntryLimit: number,
    setisPortalAuth: any;
}

export const useServerConnection = () => {
    const history = useHistory();

    const [online, setOnline] = useState<boolean>(true);
    //Used to show loading indicator - stops double submit
    const [loading, setLoading] = useState<boolean>(false);
    //Agree to TCs
    const [isportalAuth, setisPortalAuth] = useState(false);
    const [portalAPI, setPortalAPI] = useState('');
    const [portalList, setPortalList] =  useState<string[]>([]);
    const [timeEntryLimit, setTimeEntryLimit] = useState<number>(1);
    //token
    const [token, setToken] = useState<Token | undefined>(undefined);

    //Setup offline storage
    const store = new Storage();
    store.create();
    //Modules
    //online/offline listener
    useEffect(() => {
      window.addEventListener("offline", () => {
        setOnline(false);
      });
      window.addEventListener("online", () => {
        setOnline(true);
      });
  
      return () => {
        
        window.removeEventListener("offline", () => {
          setOnline(false);
        });
        window.removeEventListener("online", () => {
          setOnline(true);
        });
      };
    }, []);

    //On first load
    useEffect(() => {
      console.log('API useEffect');
      (async function getStorage() {
        //Load all data from local storage
        console.log('Load from local storage');
        const tmpPortalAPI = await store.get(`${STORE}api`);
        if(tmpPortalAPI){
          setisPortalAuth(true);
          setPortalAPI(tmpPortalAPI);
        }
      })();
    }, []);

    useEffect(() => {
      console.log('API useEffect');
      (async function getStore() {
        //Load all data from local storage
        const tmpPortalAPIList = await store.get(`${STORE}apilist`);
        console.log(tmpPortalAPIList);
        
        if(tmpPortalAPIList){
          let temp = JSON.parse(tmpPortalAPIList);
          setPortalList([...temp]);
        }
      })();
    }, []);

    useEffect(() => {
      (async function saveStorage() {
          if(portalList.length > 0){
            console.log("list", portalList);
    
            store.set(`${STORE}apilist`, JSON.stringify(portalList));
    
            console.log(await store.get(`${STORE}apilist`));
          }
      })();
    }, [portalList]);

    const authentication = useAuthenticationModule(portalAPI, store, setLoading, online, token, setToken, setTimeEntryLimit);
    
    const rosters = useRosterModule( portalAPI, store, token, setLoading, authentication?.user?.id ); 

    const timesheetEntries = useTimesheetEntryModule( portalAPI, store, token, setLoading, authentication?.user?.id, rosters ); 
    
    const clearPortalAPI = () => {
        console.log('clearAgree');
        store.set(`${STORE}api`,portalAPI);
        setisPortalAuth(true);
    };

    const submitPortalAPI = async (portal: string) => {
        let portalAPI = `https://${portal}.taro.solutions/api`;   //for local: daktech.dev || for live: taro.solutions
        try {
          const response = await fetch(`${portalAPI}/auth.json`,{
            method: 'GET'
          });
          const result = await response.json();
          if(result.message === 'Hello'){
            setPortalAPI(portalAPI);
            store.set(`${STORE}api`,portalAPI);
            setisPortalAuth(true);
            if(!portalList.includes(portal)){
              setPortalList([...portalList, portal]);
            }
            return true;
          }else{
            return false;
          }
        } catch (error) {
          console.log(error); 
          return false;
        }
    }

    

    const logOut = async () => {
      if (!token) return false;

      setLoading(true);
      try {
        // store.clear();
        await store.remove(`${STORE}api`);
        await store.remove(`${STORE}token`);
        await store.remove(`${STORE}email_token`);
        await store.remove(`${STORE}rosters`);
        await store.remove(`${STORE}user`);
        setLoading(false); 
        window.location.reload();
      } catch (err) {

      }
    }

    const api: Api = {
        authentication,
        rosters,
        timesheetEntries,
        online,
        loading,
        isportalAuth,
        portalAPI, 
        logOut,
        clearPortalAPI,
        submitPortalAPI,
        portalList,
        timeEntryLimit,
        setisPortalAuth,
    };

    return api; 

};

export default useServerConnection;
